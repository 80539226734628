<template>
  <div>
    <base-header class="pb-6" type="">
      <div class="row align-items-center"></div>
    </base-header>
  </div>
</template>
<script>
// Components
import BaseHeader from "@/components/BaseHeader";

export default {
  components: {
    BaseHeader,
  },
  data() {
    return {};
  },
};
</script>
<style></style>
